/*const mobileBtn = document.querySelector('.mobile-menu-btn');
const mobileNav = document.querySelector('.mobile-menu');
const toggler = document.querySelector('.toggler');
mobileBtn.addEventListener('click', () => {
    mobileNav.classList.toggle('mobile-menu--open');
});

toggler.addEventListener('click', () => {
    mobileNav.classList.toggle('mobile-menu--open');
});
*/